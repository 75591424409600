import MeduimPrimaryButton from "../components/MediumPrimaryButton";
import { useRef, useState, useEffect } from "react";
import OurTeamCard from "../components/OurTeamCard";
import PatternCard from "../components/PatternCard";
import { VariableLabel } from "../components/VariableLabel";
import Pattern1 from "../components/img/Pattern 1.svg";
import Pattern2 from "../components/img/Pattern 2.svg";
import Pattern3 from "../components/img/Pattern 3.svg";
import PatternCard21 from "../components/img/PatternCard2 1.svg";
import PatternCard22 from "../components/img/PatternCard2 2.svg";
import PatternCard23 from "../components/img/PatternCard2 3.svg";
import PatternCard31 from "../components/img/PatternCard3 1.svg";
import PatternCard32 from "../components/img/PatternCard3 2.svg";
import PatternCard33 from "../components/img/PatternCard3 3.svg";
import PatternCard41 from "../components/img/PatternCard4 1.svg";
import PatternCard42 from "../components/img/PatternCard4 2.svg";
import PatternCard43 from "../components/img/PatternCard4 3.svg";
import PatternCard51 from "../components/img/PatternCard5 1.svg";
import PatternCard52 from "../components/img/PatternCard5 2.svg";
import PatternCard53 from "../components/img/PatternCard5 3.svg";
import PatternCard61 from "../components/img/PatternCard6 1.svg";
import PatternCard62 from "../components/img/PatternCard6 2.svg";
import PatternCard63 from "../components/img/PatternCard6 3.svg";
// import Play from "../components/img/video-img.svg";
import namaa from "../components/img/namaa-logo.png";
import nuri from "../components/img/nuri-logo.png";

import "./aboutus.css";
import PhotoAlbum from "../components/PhotoAlbum";
import Footer from "../components/Footer";
import { SlideArrowRight } from "../components/SlideArrowRight";
import { SlideArrowLeft } from "../components/SlideArrowLeft";
import SmallPrimaryButton from "../components/SmallPrimaryButton";
import album1 from "../components/img/البرمجة 1.webp";
import album2 from "../components/img/التجارة الالكترونية.webp";
import album3 from "../components/img/التسويق 1.webp";
import album4 from "../components/img/التسويق 2.webp";
import album5 from "../components/img/التصميم 1.webp";
import album6 from "../components/img/التصميم الداخلي.webp";
import album7 from "../components/img/المحاسبة المالية.webp";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import TeamCardsContainer from "../components/TeamCardsContainer";

//team images
import team1 from "../components/img/Nazire.webp";
import team2 from "../components/img/ahmad.jpg";
import team3 from "../components/img/abdullahhalil.webp";
import team4 from "../components/img/Nazire.webp";
import team5 from "../components/img/hamza.webp";
import team6 from "../components/img/habiba.webp";
import team7 from "../components/img/omar.webp";
import team8 from "../components/img/abdullah.webp";
import team9 from "../components/img/braa.webp";
import team10 from "../components/img/abdulrahman.webp";
import team11 from "../components/img/mohammed.webp";
import team12 from "../components/img/lara.webp";
import team13 from "../components/img/karam.webp";
import team14 from "../components/img/mert.jpg";

const yourPhotosArray = [
  album1,
  album2,
  album3,
  album4,
  album5,
  album6,
  album7,
];

const usePreloadImages = (imageUrls) => {
  useEffect(() => {
    const preloadImage = (url) => {
      const img = new Image();
      img.src = url;

      // // Log to indicate when an image starts and finishes loading
      // img.onload = () => {
      //   console.log(`Image preloaded: ${img.src}`);
      // };
      // img.onerror = () => {
      //   console.error(`Failed to preload image: ${img.src}`);
      // };
    };

    imageUrls.forEach((url) => {
      preloadImage(url);
    });
  }, [imageUrls]);
};

export const AboutUs = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [isReversed, setIsReversed] = useState(false);
  const teamContainerRef = useRef(null);

  const { t } = useTranslation();
  const html = document.querySelector("html");

  //const teamCards = TeamCardsContainer();
  const teamCards = [
    <OurTeamCard
      // href={
      //   "https://www.linkedin.com/in/abdulrahman-alesawy-a1250ab5?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABh1VS4BpC7Ogh3erwPVxAUDyKLfSxLFuAA&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3B5IEmZX%2F3TmSfMXcOzDBuqg%3D%3D"
      // }
      className="our-team-card"
      image={team10}
      name={t("TeamMem10")}
      department={t("dep10")}
    />,
    <OurTeamCard
      href={""}
      className="our-team-card"
      name={t("TeamMem11")}
      department={t("dep11")}
      image={team11}
    />,
    <OurTeamCard
      href={""}
      className="our-team-card"
      name={t("TeamMem14")}
      department={t("dep14")}
      image={team14}
    />,
    <OurTeamCard
      href={""}
      className="our-team-card"
      image={team8}
      name={t("TeamMem8")}
      department={t("dep8")}
    />,

    <OurTeamCard
      // href={
      //   "https://www.linkedin.com/in/albaraa-alsudani-ba72a021b?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAADdkuK8BWrB1hdtW4dZNQC01XiCK1NuqmAg&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_people%3BsBVkwuu9RjiXK2NYJ%2FXKgQ%3D%3D"
      // }
      className="our-team-card"
      image={team9}
      name={t("TeamMem9")}
      department={t("dep9")}
    />,
    <OurTeamCard
      // href={""}
      className="our-team-card"
      name={t("TeamMem3")}
      department={t("dep3")}
      image={team3}
    />,
    <OurTeamCard
      // href={""}
      className="our-team-card"
      name={t("TeamMem12")}
      department={t("dep12")}
      image={team12}
    />,

    <OurTeamCard
      // href={
      //   "https://www.linkedin.com/in/omar-haddad-419b811ba?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAADMTNRUBSEThAKlOv-2oxTyS48sK7iDfVhM&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_people%3B9IxXhCPYRBuFbPsXBxvpRA%3D%3D"
      // }
      className="our-team-card"
      name={t("TeamMem7")}
      department={t("dep7")}
      image={team7}
    />,

    <OurTeamCard
      // href={""}
      className="our-team-card"
      name={t("TeamMem6")}
      department={t("dep6")}
      image={team6}
    />,

    <OurTeamCard
      // href={"/"}
      className="our-team-card"
      name={t("TeamMem2")}
      department={t("dep2")}
      image={team2}
    />,
    <OurTeamCard
      // href={""}
      className="our-team-card"
      name={t("TeamMem5")}
      department={t("dep5")}
      image={team5}
    />,
    <OurTeamCard
      // href={""}
      className="our-team-card"
      image={team13}
      name={t("TeamMem13")}
      department={t("dep13")}
    />,

    <OurTeamCard
      // href={""}
      className="our-team-card"
      image={team1}
      name={t("TeamMem1")}
      department={t("dep1")}
    />,
  ];

  const handlePlayClick = () => {
    setShowVideo(true);
  };

  const scrollLeft = () => {
    if (teamContainerRef.current) {
      teamContainerRef.current.scrollBy({
        left: 400, // Adjust the scroll distance as per your design
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (teamContainerRef.current) {
      teamContainerRef.current.scrollBy({
        left: -400, // Adjust the scroll distance as per your design
        behavior: "smooth",
      });
    }
  };
  const patternArray = [
    Pattern1,
    Pattern2,
    Pattern3,
    PatternCard21,
    PatternCard22,
    PatternCard23,
    PatternCard31,
    PatternCard32,
    PatternCard33,
    PatternCard41,
    PatternCard42,
    PatternCard43,
    PatternCard51,
    PatternCard52,
    PatternCard53,
    PatternCard61,
    PatternCard62,
    PatternCard63,
  ];

  usePreloadImages(patternArray);

  return (
    <div className="about-us">
      {/*1 */}
      <div className="container">
        <div className="frame-25">
          <div className="frame-28">
            <div className="frame-29">
              <div className="text-wrapper-28">
                <VariableLabel property1="one" />
              </div>
              <p className="text-wrapper-28">{t("Card2big")}</p>
            </div>
          </div>
          <div className="frame-26">
            <iframe
              className="rectangle-2"
              src="https://www.youtube.com/embed/abZAhxDVUwk?si=OZpUAxz9kdBQamCi"
              title="YouTube video player"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className="frame-30">
          <div className="frame-31">
            <p className="text-wrapper-29">{t("OurVisionText")}</p>
            <div className="text-wrapper-30">{t("OurVision")}</div>
          </div>

          <div className="frame-31">
            <p className="text-wrapper-29">{t("OurMessageText")}</p>
            <div className="text-wrapper-30">{t("OurMessage")}</div>
          </div>
        </div>
      </div>

      {/*2 */}
      <div className="frame-32">
        <div className="frame-33">
          <div className="frame-29">
            <div className="text-wrapper-31"> {t("OurValues")}</div>
            <p className="text-wrapper-32">{t("ReplacedText")}</p>
          </div>
        </div>
        <div className="frame-34">
          <div className="frame-35">
            <PatternCard
              title={t("value3")}
              text={t("value3Text")}
              image1={Pattern1}
              image2={Pattern2}
              image3={Pattern3}
              property1="default"
            />
            <PatternCard
              title={t("value2")}
              text={t("value2Text")}
              image1={PatternCard21}
              image2={PatternCard22}
              image3={PatternCard23}
              property1="default"
            />
            <PatternCard
              title={t("value1")}
              text={t("value1Text")}
              image1={PatternCard31}
              image2={PatternCard32}
              image3={PatternCard33}
              property1="default"
            />
          </div>
          <div className="frame-35">
            <PatternCard
              title={t("value6")}
              text={t("value6Text")}
              image1={PatternCard41}
              image2={PatternCard42}
              image3={PatternCard43}
              property1="default"
            />
            <PatternCard
              title={t("value5")}
              text={t("value5Text")}
              image1={PatternCard51}
              image2={PatternCard52}
              image3={PatternCard53}
              property1="default"
            />
            <PatternCard
              title={t("value4")}
              text={t("value4Text")}
              image1={PatternCard61}
              image2={PatternCard62}
              image3={PatternCard63}
              property1="default"
            />
          </div>
          <div className="frame-35s">
            <PatternCard
              title={t("value3")}
              text={t("value3Text")}
              image1={Pattern1}
              image2={Pattern2}
              image3={Pattern3}
              property1="default"
            />
            <PatternCard
              title={t("value2")}
              text={t("value2Text")}
              image1={PatternCard21}
              image2={PatternCard22}
              image3={PatternCard23}
              property1="default"
            />
          </div>
          <div className="frame-35s">
            <PatternCard
              title={t("value1")}
              text={t("value1Text")}
              image1={PatternCard31}
              image2={PatternCard32}
              image3={PatternCard33}
              property1="default"
            />
            <PatternCard
              title={t("value6")}
              text={t("value6Text")}
              image1={PatternCard41}
              image2={PatternCard42}
              image3={PatternCard43}
              property1="default"
            />
          </div>
          <div className="frame-35s">
            <PatternCard
              title={t("value5")}
              text={t("value5Text")}
              image1={PatternCard51}
              image2={PatternCard52}
              image3={PatternCard53}
              property1="default"
            />
            <PatternCard
              title={t("value4")}
              text={t("value4Text")}
              image1={PatternCard61}
              image2={PatternCard62}
              image3={PatternCard63}
              property1="default"
            />
          </div>
        </div>
      </div>

      {/*3 Team Section */}
      <div className="frame-36">
        <div className="frame-33">
          <div className="frame-29">
            <div className="text-wrapper-31"> {t("Team")}</div>
            <p className="text-wrapper-32">{t("ReplacedText")}</p>
          </div>
          <div className="frame-arrow">
            {i18n.language === "ar" ? (
              <>
                <SlideArrowLeft
                  onClick={scrollLeft}
                  className="design-component-instance-node-2"
                  property1="default"
                />
                <SlideArrowRight
                  onClick={scrollRight}
                  className="slide-arrow-right-instance"
                  property1="default"
                />
              </>
            ) : (
              <>
                <SlideArrowRight
                  onClick={scrollRight}
                  className="slide-arrow-right-instance"
                  property1="default"
                />
                <SlideArrowLeft
                  onClick={scrollLeft}
                  className="design-component-instance-node-2"
                  property1="default"
                />
              </>
            )}
          </div>
        </div>

        <div className="frame-34">
          <div className="frame-37" ref={teamContainerRef}>
            {teamCards}
          </div>

          {/* <div className="frame-37 " ref={teamContainerRef}>
            {TeamCards}
          </div> */}
        </div>
      </div>

      {/*4 */}
      <div className="frame-38">
        <div className="frame-33">
          <div className="frame-29">
            <div className="text-wrapper-31">{t("Donors")}</div>
            <p className="text-wrapper-32">{t("ReplacedText")}</p>
          </div>
        </div>
        <div className="frame-39">
          <div className="frame-40">
            <img className="image" alt="Image" src={namaa} />
            <img className="image" alt="Logo" src={nuri} />
          </div>
          {/* <SmallPrimaryButton text={t("BeDonor")} width={160} height={40} /> */}
        </div>
      </div>

      {/*5 */}
      <div className="frame-41">
        <div className="frame-33">
          <div className="frame-29">
            {/* <div className="text-wrapper-31"> {t("Team")}</div> */}
            <div className="text-wrapper-31"> {t("Album")}</div>
          </div>
        </div>
        <PhotoAlbum photos={yourPhotosArray} />
      </div>

      {/*6 */}
      <div className="footer-container">
        <Footer className="footer-instance" />
      </div>
    </div>
  );
};
