import { useRef, useEffect, useState } from "react";
import { SlideArrowRight } from "../components/SlideArrowRight";
import { SlideArrowLeft } from "../components/SlideArrowLeft";
import { EmailNewsletter } from "../components/EmailNewsletter";
import { AnimationComponent } from "../components/AnimationComponent";
import { LatestNewsCard } from "../components/LatestNewsCard";
import Square from "../components/img/square.svg";
import Play from "../components/img/video-img.svg";
import Patter7 from "../components/img/PatternHome7.svg";
import Pattern5 from "../components/img/PatternCard2 2.svg";
import Ellipse1 from "../components/img/Ellipse1.webp";
import Ellipse2 from "../components/img/Ellipse2.webp";
import Ellipse3 from "../components/img/Ellipse3.webp";
import Ellipse4 from "../components/img/Ellipse4.webp";
import Ellipse5 from "../components/img/Ellipse5.webp";
import ServiceImage1 from "../components/img/برامج التدريب المهني.webp";
import ServiceImage2 from "../components/img/المساحة المشتركة.webp";
import ServiceImage3 from "../components/img/برنامج ريادة الأعمال.webp";
import Footer from "../components/Footer";
import { Testimonial } from "../components/Testimonial";
import "./home.css";
import { LabelMoreDetail } from "../components/LabelMoreDetail";
import { ServiceCard } from "../components/ServiceCard";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import LatestNewsSection from "../components/LastestNewsSection";

import { TestimonialsContainer } from "../components/TestimonialsContainer";

export const Home = () => {
  const { t } = useTranslation();
  const html = document.querySelector("html");

  const testimonialContainerRef = useRef(null);
  const latestContainerRef = useRef(null);
  const [fontSizeLabel, setFontSizeLabel] = useState(14); // Default font size
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const testimonialCards = [
    <Testimonial
      image={Ellipse5}
      text={
        <>
          {t("BusinessmanQuote1")}
          <br />
          {t("BusinessmanQuote2")}
        </>
      }
      name={t("BusinessmanName2")}
      department={t("BusinessmanDepartment2")}
    />,
    <Testimonial
      image={Ellipse4}
      text={t("CharityChairmanQuote")}
      name={t("CharityChairmanName")}
      department={t("CharityChairmanDepartment")}
    />,
    <Testimonial
      image={Ellipse1}
      text={t("KuwaitAmbassadorQuote")}
      name={t("KuwaitAmbassadorName")}
      department={t("KuwaitAmbassadorDepartment")}
    />,
    <Testimonial
      image={Ellipse2}
      text={t("UNAdvisorQuote")}
      name={t("UNAdvisorName")}
      department={t("UNAdvisorDepartment")}
    />,
    <Testimonial
      image={Ellipse3}
      text={t("TurkishAdvisorQuote")}
      name={t("TurkishAdvisorName")}
      department={t("TurkishAdvisorDepartment")}
    />,
  ];

  const scrollTestimonialsLeft = () => {
    console.log("Scroll Left button clicked");
    testimonialContainerRef.current.scrollBy({
      left: 300, // Adjust this value as needed
      behavior: "smooth",
    });
  };

  const scrollTestimonialsRight = () => {
    console.log("Scroll Right button clicked");
    testimonialContainerRef.current.scrollBy({
      left: -300, // Adjust this value as needed
      behavior: "smooth",
    });
  };

  const handleClick = () => {
    console.log("Handle Click executed");
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling effect
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1180) {
        setFontSizeLabel(8); // Set smaller font size for screens <= 1180px
      } else {
        setFontSizeLabel(14);
      }

      if (testimonialContainerRef.current) {
        testimonialContainerRef.current.scrollLeft =
          testimonialContainerRef.current.scrollWidth;
      }

      if (latestContainerRef.current) {
        latestContainerRef.current.scrollLeft =
          latestContainerRef.current.scrollWidth;
      }

      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the correct font size on component mount
    handleResize();

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`home ${i18n.language === "en" ? "ltr" : "rtl"}`}>
      <div className="div">
        <div className="moving-text-container">
          <p
            className={`type-text ${
              i18n.language === "en" ? "AniEng" : "AniArb"
            }`}
          >
            {t("movingText")}
          </p>
          <p
            className={`type-text ${
              i18n.language === "en" ? "AniEng" : "AniArb"
            }`}
          >
            {t("movingText")}
          </p>
        </div>
        <div className="overlap">
          <div className="frame-30">
            <div className="frame-31">
              <iframe
                className="rectangle-5"
                src="https://www.youtube.com/embed/abZAhxDVUwk?si=OZpUAxz9kdBQamCi"
                title="YouTube video player"
                allowFullScreen
              ></iframe>
              {/* <img className="frame-32" alt="Frame" src={Play} /> */}
            </div>
            {/* <img className="group-3" alt="Group" src={Patter7} /> */}
            <img className="group-5" alt="Group" src={Pattern5} />
          </div>
        </div>

        <div className="frame-33">
          <div className="frame-34">
            <div className="text-wrapper-34">{t("OurServices")}</div>
            <p className="text-wrapper-35">{t("OurServicesText")}</p>
          </div>
          <div className="frame-35">
            <ServiceCard
              imagesrc={ServiceImage2}
              property1="default"
              title={t("WorkSpace")}
              text={t("WorkSpaceText")}
              rectangle="rectangle-41-8.webp"
            />
            <ServiceCard
              imagesrc={ServiceImage1}
              className="design-component-instance-node-2"
              property1="default"
              title={t("VocationalTrainingProgrammes")}
              text={t("ServeiceCard1")}
              rectangle="rectangle-41-6.webp"
            />
            <ServiceCard
              imagesrc={ServiceImage3}
              className="design-component-instance-node-2"
              property1="default"
              title={t("EntrepreneurshipProgram")}
              text={t("EntrepreneurshipProgramText")}
              rectangle="rectangle-41-7.webp"
            />
          </div>
        </div>
        <div className={`overlap-2 ${i18n.language === "en" ? "ltr" : ""}`}>
          <div className="div-3738">
            <div className="frame-37">
              <div className="text-wrapper-34"> {t("Testimon")}</div>
              <p className="text-wrapper-35">{t("ReplacedText")}</p>
            </div>
            {/* <div className="frame-38">
              {i18n.language === "en" ? (
                <>
                  <SlideArrowRight
                    onClick={scrollTestimonialsRight}
                    className="slide-arrow-right-instance"
                    property1="default"
                  />
                  <SlideArrowLeft
                    onClick={scrollTestimonialsLeft}
                    className="design-component-instance-node-2"
                    property1="default"
                  />
                </>
              ) : (
                <>
                  <SlideArrowLeft
                    onClick={scrollTestimonialsLeft}
                    className="design-component-instance-node-2"
                    property1="default"
                  />
                  <SlideArrowRight
                    onClick={scrollTestimonialsRight}
                    className="slide-arrow-right-instance"
                    property1="default"
                  />
                </>
              )}
            </div> */}
          </div>

          <div className="frame-36">
            {/* {testimonialCards} */}

            <TestimonialsContainer />
          </div>
        </div>

        <div className="frame-39">
          <div className="frame-40">
            <div className="frame-41">
              <div className="div-moredetail">
                <Link onClick={handleClick} to="/news">
                  <LabelMoreDetail text={t("More")} />
                </Link>
              </div>
              <div className="text-wrapper-34"> {t("CoreLastestNews")}</div>
            </div>
          </div>
          <div className="frame-42" ref={latestContainerRef}>
            <div>
              {/* <LatestNewsSection size="square" className="frame-44" /> */}
              <div className="frame-42" ref={latestContainerRef}>
                <div className="frame-44">
                  <LatestNewsCard
                    size="square"
                    title={t("newsTitle1")}
                    text={t("newsTitle2")}
                    imageSrc={Square}
                    fontSizeLabel={fontSizeLabel}
                    disableLabel={true}
                  />

                  <LatestNewsCard
                    size="square"
                    title={t("newsTitle1")}
                    text={t("newsTitle2")}
                    imageSrc={Square}
                    fontSizeLabel={fontSizeLabel}
                    disableLabel={true}
                  />
                  <LatestNewsCard
                    size="square"
                    title={t("newsTitle1")}
                    text={t("newsTitle2")}
                    imageSrc={Square}
                    fontSizeLabel={fontSizeLabel}
                    disableLabel={true}
                  />
                  <LatestNewsCard
                    size="square"
                    title={t("newsTitle1")}
                    text={t("newsTitle2")}
                    imageSrc={Square}
                    fontSizeLabel={fontSizeLabel}
                    disableLabel={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="email-div">
          <EmailNewsletter />
        </div>
        <div className="animation">
          <AnimationComponent />
        </div>
        <div className="div-43">
          <div className="frame-43">
            <div className="text-wrapper-38">{t("AboutCoreIstanbul")}</div>
            <p className="text-wrapper-37">{t("AboutCoreText")}</p>
          </div>
        </div>
      </div>
      <Footer className="footer-instance" />
    </div>
  );
};
